import { PageSection } from "../../components/PageSection";
import { Products } from "../../components/Products";
import React from "react";
import { Head } from "../../components/Head";
import { TopVendorLogo } from "../../components/TopVendorLogo";
import { ContentGroups } from "../../constants/contentGroups";

const ProductListPage = () => (
    <>
        <Head
            contentGroup={ContentGroups["product-aware"]}
            title="Products"
            description="Easy Agile User Story Maps, Roadmaps and Programs. Plugins for Jira."
        />
        <Products />
        <PageSection>
            <TopVendorLogo />
        </PageSection>
    </>
);

export default ProductListPage;
