import { gridSquares } from "../styles/grid";
import { List } from "./List";
import { PageSectionContainer } from "./PageSection";
import { GatsbyImage } from "gatsby-plugin-image";

import React from "react";
import styled from "styled-components";
import { Heading } from "./Typography/Heading";
import { Hyperlink } from "../components/Link/Hyperlink";
import { Flex } from "../components/ThemeFlex";
import { MarketplaceStats } from "./ProductPageContents/MarketplaceStats";
import Button from "../components/Button";
import { graphql, useStaticQuery } from "gatsby";

import { PRODUCT } from "../constants/products";
import { EABlockContent } from "./BlockContent";

const getProductPageSlug = (product) => {
    const slug = product?.productPage?.slug?.current;
    if (slug) {
        // If Sanity knows the slug, return it
        return `/products/${slug}`;
    }

    // fallback until we have product pages for everyone
    const hardCodedProduct = Object.values(PRODUCT).find((fallbackProduct) => {
        return fallbackProduct.appId === product.appId;
    });
    return hardCodedProduct.slug;
};

export const Products = () => {
    // for now, sort by atlassianProductId - eventually make this
    // selectable in Sanity

    const {
        allSanityProduct: { products },
    } = useStaticQuery(graphql`
        query {
            allSanityProduct(sort: { fields: atlassianProductId }) {
                products: nodes {
                    appId: atlassianProductId
                    icon {
                        asset {
                            gatsbyImageData(placeholder: NONE)
                        }
                    }
                    title
                    productPage {
                        slug {
                            current
                        }
                    }
                    productListing {
                        title
                        buttonText
                        _rawContent
                    }
                }
            }
        }
    `);

    return (
        <>
            <Container>
                <Flex flexDirection="column">
                    <Heading level="h1" levelStyle="h1">
                        Easy Agile Apps
                    </Heading>

                    <Flex marginTop="1.5em" marginBottom="4em">
                        <CenteredSpan>
                            Installs in seconds. Incredibly easy to setup and
                            use. <br />
                            Start your 30 day free trial or{" "}
                            <UnderlinedLink href="/demo">
                                <span> schedule a demo</span>
                            </UnderlinedLink>
                            .
                        </CenteredSpan>
                    </Flex>
                </Flex>
                <TwoByTwoGrid>
                    {Object.values(products).map((product) => {
                        return (
                            <ProductCard>
                                <Hyperlink href={getProductPageSlug(product)}>
                                    <>
                                        <OfferBadge>
                                            30 day free trial
                                        </OfferBadge>
                                        <Flex
                                            width="100%"
                                            flexDirection="column"
                                            alignItems="start"
                                        >
                                            <ProductIcon product={product} />
                                            <Title level="h2" levelStyle="h4">
                                                {product?.productListing?.title}
                                            </Title>
                                            <MarketplaceStats
                                                appId={product.appId}
                                                showFortified={false}
                                                showReviews={true}
                                                showInstalls={true}
                                                showNumberOfReviews={false}
                                            ></MarketplaceStats>
                                            <ContentWrapper>
                                                <EABlockContent
                                                    blocks={
                                                        product?.productListing
                                                            ?._rawContent
                                                    }
                                                ></EABlockContent>
                                            </ContentWrapper>
                                        </Flex>
                                        <Button
                                            variation="primary"
                                            href={getProductPageSlug(product)}
                                        >
                                            Features & Pricing
                                        </Button>
                                    </>
                                </Hyperlink>
                            </ProductCard>
                        );
                    })}
                </TwoByTwoGrid>
            </Container>
        </>
    );
};

const ProductIcon = ({ product }) => {
    return (
        <GatsbyImage
            style={{
                width: "64px",
            }}
            image={product?.icon?.asset?.gatsbyImageData}
            alt={product?.productListing?.title}
        />
    );
};

const Container = styled(PageSectionContainer)`
    padding-top: 80px;
    padding-left: min(48px);
    padding-right: min(48px);

    @media (max-width: ${(p) => p.theme.breakpoint.xl}) {
        padding-left: none;
        padding-right: none;
    }
`;

const Title = styled(Heading)`
    margin-top: 0.5em;
    margin-bottom: 0em;
`;
const CenteredSpan = styled.span`
    text-align: center;
    margin-bottom: 50px;
`;
const UnderlinedLink = styled(Hyperlink)`
    text-decoration: underline;
`;

const ContentWrapper = styled.div`
    ul {
        margin-top: ${gridSquares(2)};
        margin-bottom: ${gridSquares(1)};
    }

    padding-top: ${gridSquares(2)};
    padding-bottom: ${gridSquares(1)};
    margin-bottom: ${gridSquares(2)};
`;

const OfferBadge = styled.div`
    position: absolute;
    right: 16px;
    top: 16px;
    color: ${(p) => p.theme.color.primary.main};
    border: 1px solid ${(p) => p.theme.color.primary.main};
    border-radius: ${(p) => p.theme.borderRadius.l};
    padding-left: 12px;
    padding-right: 12px;
    padding-top: 3px;
    padding-bottom: 3px;
    font-weight: 600;
    font-size: 12px;
    text-transform: uppercase;
`;

const TwoByTwoGrid = styled.div`
    display: grid;
    grid-template-columns: 1fr 1fr;
    column-gap: ${gridSquares(1)};
    row-gap: ${gridSquares(1)};
    & > * {
        align-self: stretch;
    }

    //break and adjust margins depending on page size
    @media (max-width: ${(p) => p.theme.breakpoint.xl}px) {
        grid-template-columns: auto;
        margin: 0px 110px;
    }

    @media (max-width: ${(p) => p.theme.breakpoint.lg}px) {
        margin: 0px 50px;
    }

    @media (max-width: ${(p) => p.theme.breakpoint.md}px) {
        margin: 0px;
    }
`;

const ProductCard = styled.div`
    cursor: hand;
    position: relative;
    padding: ${gridSquares(2)};
    a {
        white-space: nowrap;
    }
    background-color: ${(p) => p.theme.color.card.lightWite};
    border-radius: ${(p) => p.theme.borderRadius.l};

    //on small screens, wrap text and expand vertically to fit
    @media (max-width: ${(p) => p.theme.breakpoint.lg}px) {
        a {
            white-space: normal;
        }
    }
`;
