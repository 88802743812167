/* tslint:disable:object-literal-sort-keys */

import {
    EA_PROGRAMS_MARKETPLACE_URL,
    EA_ROADMAPS_MARKETPLACE_URL,
    EA_TEAMRHYTHM_MARKETPLACE_URL,
    EA_PERSONAS_MARKETPLACE_URL,
} from "../constants/urls";

// Based on 'EasyAgileProductType' enum from ea-kit - be weary of changing
export enum EasyAgileProductType {
    All = "All",
    Personas = "Personas",
    Programs = "Programs",
    Roadmaps = "Roadmaps",
    UserStoryMaps = "UserStoryMaps",
    TeamRhythm = "TeamRhythm",
}

export const PRODUCT_MARKETPLACE_LISTING = {
    [EasyAgileProductType.Personas]: EA_PERSONAS_MARKETPLACE_URL,
    [EasyAgileProductType.Programs]: EA_PROGRAMS_MARKETPLACE_URL,
    [EasyAgileProductType.Roadmaps]: EA_ROADMAPS_MARKETPLACE_URL,
    [EasyAgileProductType.UserStoryMaps]: EA_TEAMRHYTHM_MARKETPLACE_URL,
    [EasyAgileProductType.TeamRhythm]: EA_TEAMRHYTHM_MARKETPLACE_URL,
};

export const PRODUCT = {
    teamrhythm: {
        productType: EasyAgileProductType.UserStoryMaps,
        name: "TeamRhythm",
        slug: "/products/teamrhythm",
        appId: "1212078",
        marketplaceUrl: EA_TEAMRHYTHM_MARKETPLACE_URL,
    },
    programs: {
        productType: EasyAgileProductType.Programs,
        name: "Programs",
        slug: "/products/programs",
        appId: "1219491",
        marketplaceUrl: EA_PROGRAMS_MARKETPLACE_URL,
    },
    personas: {
        productType: EasyAgileProductType.Personas,
        name: "Personas",
        slug: "/products/personas",
        appId: "1221392",
        marketplaceUrl: EA_PERSONAS_MARKETPLACE_URL,
    },
    roadmaps: {
        productType: EasyAgileProductType.Roadmaps,
        name: "Roadmaps",
        slug: "/products/roadmaps",
        appId: "1215695",
        marketplaceUrl: EA_ROADMAPS_MARKETPLACE_URL,
    },
};
