import { gridSquares } from "../styles/grid";
import React from "react";
import styled from "styled-components";
import topVendorSvg from "../../content/imgs/topVendor.svg";

const Img = styled.img`
    width: 100%;
    max-width: ${gridSquares(24)};
`;

interface PropsType {
    className?: string;
}

export const TopVendorLogo = ({ className }: PropsType) => (
    <Img
        className={className}
        alt="Platinum Marketplace Partner text with Atlassian's logo"
        src={topVendorSvg}
    />
);
